import React from "react";
import PostList from "../components/PostList";
import MainNews from "../components/MainNews";
import MainPostList from "../components/MainPostList";
import TrailerSlider from "../components/TrailerSlider";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import {Helmet} from "react-helmet";
export default function HomePage() {
   return (
  
    <div className="page-container bg-dark">
     <Helmet>
     <meta name="keywords" content="Chithrabhumi, malayalam, mohanlal, mammootty,cinema, cinema news"/>
    </Helmet>
    <Container>
      <Row className="pb-5">   
      <Col sm={8} xs={12}>
        <div><MainNews></MainNews></div>
      </Col>
      <Col sm={4} xs={12}><div><MainPostList></MainPostList></div></Col>
      </Row>
    <Row>
      
      <h2 className="carouselHead">TRAILERS / TEASERS / POSTERS</h2>
      <div>
      <Row>
      <TrailerSlider></TrailerSlider></Row></div>
    </Row>
      <Row>
        <Col sm={8} xs={12}><div><PostList/></div></Col>
        <Col sm={4} xs={12} className="border-left">
      
        
        </Col>
      </Row>
    </Container>
     
    </div>
  );
}
