import React from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import {Helmet} from "react-helmet";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SinglePost from '../components/SinglePost';
import SidePost from "../components/SidePost";

const GET_POSTS_BY_ID = gql`
  query MyQuery($id:ID!) {
    post(id: $id, idType: DATABASE_ID) {
      title
      date
      content
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
      tags {
        nodes {
          name
          slug
        }
      }
      categories {
        nodes {
          uri
          name
        }
      }
      seo {
        canonical
        metaKeywords
        opengraphImage {
          altText
          sourceUrl
        }
        opengraphDescription
        opengraphTitle
      }
    }
  }
`;
export default function PostPage() {
    let { id } = useParams();
    
  const { loading, error, data } = useQuery(GET_POSTS_BY_ID, {
    variables: {
      id : id
    }
  });
  const postFound = Boolean(data ? data:'');
  
   return (
    
      <div className="page-container bg-dark">
      <Helmet>
      <link rel="canonical" href={postFound?data.post.seo.canonical:''} />
      <meta name="twitter:title" content={postFound?data.post.seo.opengraphTitle:''}/>
      <meta name="twitter:description" content={postFound?data.post.seo.opengraphDescription:''}/>
      <meta name="twitter:url" content={postFound?data.post.seo.canonical:''}/>
      <meta name="twitter:image" content={postFound?data.post.seo.opengraphImage.sourceUrl:''}/>
      <meta property="og:title" content={postFound?data.post.seo.opengraphTitle:''} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={postFound?data.post.seo.canonical:''} />
      <meta property="og:image" content={postFound?data.post.seo.opengraphImage.sourceUrl:''} />
      <meta property="og:description" content={postFound?data.post.seo.opengraphDescription:''} /> 
    </Helmet>
      <Container className="p-3">
      <Breadcrumb>
      <Breadcrumb.Item href="/" className="fs-6 text-light">Home</Breadcrumb.Item>
      <Breadcrumb.Item active className="fs-6 malayalam">
      {postFound?data.post.title:''}
      </Breadcrumb.Item>
      
    </Breadcrumb>

      {loading ? (
        <p className="text-secondary">Loading...</p>
      ) : error ? (
        <p className="text-secondary">Error: {error.message}</p>
      ) : !postFound ? (
        <p className="text-secondary">Post could not be found.</p>
      ) : (
        <Row>
        <Col sm={9} className="justify-content-start overflow-hidden">
       <SinglePost post={data.post}></SinglePost>
       
        </Col>
        <Col sm={3}>
        <h2 className="fs-4 py-2 english mb-3 text-warning border-top border-bottom">Latest</h2>
        <SidePost limit={5}></SidePost>
        </Col>
      </Row>
        
      )}
      </Container>
    </div>
    
  )
 
}
