import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// This is the query that Apollo Client will send to the WP site.
const POSTS_TAG_QUERY = gql`
  query POSTS_TAG_QUERY($tag:String!){
    posts(where: {tag: $tag}, first: 3) {
      nodes {
        postId
        date
        uri
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;
export default function RelatedTags(post) {
  const { loading, error, data } = useQuery(POSTS_TAG_QUERY,{
    variables: {
      tag : post.post.slug
    }});
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
    return ( <Row className="pb-4">
        {data.posts.nodes.map(({ title,featuredImage,uri}) => (
      <Col sm={4} xs={6} key={title}>
        <img src={featuredImage.node.sourceUrl} alt="" className="img-fluid"/>
        <h4 className="malayalam text-secondary fs-5 pt-3">
        <Link to={`${uri}`} className="text-info text-decoration-none">  {title}</Link>
        </h4>
      </Col>
      ))}</Row>)
}