import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { Calendar } from 'react-bootstrap-icons';
const formatDate = (date) => new Date(date).toLocaleDateString();
const POSTS_SEARCH_QUERY = gql`
  query POSTS_SEARCH_QUERY{
    posts(first: 1, where: {categoryNotIn: "trailer"}) {
      nodes {
        excerpt
        date
        uri
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
      
    }
  }
`;
export default function MainNews() {
  const { loading, error, data } = useQuery(POSTS_SEARCH_QUERY);
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  return data.posts.nodes.map(({ uri, title,featuredImage,excerpt,date}) =>
  <Card className="bg-dark text-white mainnews">
    <Card.Img src={featuredImage.node.sourceUrl} alt="" />
      <Card.ImgOverlay>
        <Card.Title className="malayalam"><Link to={`${uri}`} key={title} className=" text-light text-decoration-none"> {title}</Link> </Card.Title>
        <Card.Text>
        <div className="malayalam text-light">
         {(excerpt.substring(3,150)+'...')}
        </div>
        </Card.Text>
        <Card.Text><Calendar className="text-warning"></Calendar> {formatDate(date)}</Card.Text>
      </Card.ImgOverlay>
    </Card>
    


    
      );
    
    
}