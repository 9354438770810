import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
const POSTS_TRAILER_QUERY = gql`
  query POSTS_TRAILER_QUERY{
    posts(first: 4, where: {categoryName: "trailer,teaser"}) {
      nodes {
        postId
        uri
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;
export default function TrailerSlider() {
  const { loading, error, data } = useQuery(POSTS_TRAILER_QUERY);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
    return data.posts.nodes.map(({ uri,postId, title,featuredImage}) => (
      <Col md={3} sm={2} xs={6}><div className="trailerblk" key={postId} style={{backgroundImage: `url(${featuredImage.node.sourceUrl})`}}>
        <span className="overlay"></span>
        <span className="trailertxt malayalam fs-6 p-4">
        <Link to={`${uri}`} key={title} className=" text-light text-decoration-none">
          {title}
          </Link>
          </span>
      </div></Col>
    
    ));
}