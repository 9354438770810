import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// This is the query that Apollo Client will send to the WP site.
const POSTS_SEARCH_QUERY = gql`
  query POSTS_SEARCH_QUERY($id:Int!){
    posts(first: $id) {
      nodes {
        categories {
          nodes {
            uri
            
            name
          }
        }
        uri
        date
        excerpt
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;
export default function SidePost(limit) {
  const { loading, error, data } = useQuery(POSTS_SEARCH_QUERY,{
    variables: {
      id : limit.limit
    }
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
    return data.posts.nodes.map(({ uri,title,featuredImage,categories}) => (
      
      <Row className="pb-4" key={title}>
      <Col sm={5} xs={12}><img src={featuredImage.node.sourceUrl} alt="" className="img-fluid"/></Col>
      <Col sm={7} xs={12}>
     
        <div>
        <ul className="list-unstyled">
          {categories.nodes.map((category) => {
            const { uri, name } = category;
            return (
              <Link to={`${uri}`} key={name} className=" text-info">
                <li key={name}><span>{name}</span></li>
              </Link>
            );
          })}
        </ul>
      </div>
    
        <h2 className="malayalam fs-6">
        <Link to={`${uri}`} className="text-decoration-none text-secondary ">{title}</Link></h2>
        
      </Col>
      
      </Row>
    
      ));
}