import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Nav from 'react-bootstrap/Nav';
const nav=gql`
query NAV_QUERY{
    menu(id: "dGVybToxNDU=") {
        menuItems {
          nodes {
            menuItemId
            uri
            label
          }
        }
      }
  }`;
export default function Navmenu(){
    const { loading, error, data } = useQuery(nav);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error : {error.message}</p>;
    return data.menu.menuItems.nodes.map(({ label, uri, menuItemId }) => (
        <span key={menuItemId}>
        <Nav.Link className="text-warning fs-5 english" to={uri} href={uri}>
        
              {label}
           
         </Nav.Link>   
         </span>
));
}