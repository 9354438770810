import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { Calendar } from 'react-bootstrap-icons';
const formatDate = (date) => new Date(date).toLocaleDateString();

const POSTS_SEARCH_QUERY = gql`
  query POSTS_SEARCH_QUERY{
    posts(first: 5, where: {offsetPagination: {offset: 1, size: 6}}) {
      nodes {
        categories {
          nodes {
            id
            link
            name
          }
        }
        postId
        date
        uri
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;
export default function MainPostList() {
  
  const { loading, error, data } = useQuery(POSTS_SEARCH_QUERY);
  const postFound = Boolean(data ? data:'');
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  if( !postFound) return <p className="text-secondary">Post could not be found.</p>;
  else{
    return data.posts.nodes.map(({ uri,postId, title,featuredImage,date}) => (
     
     <Row className="pb-2 mainpostheight" key={postId}>
      <Col sm={4} xs={6}><img src={featuredImage.node.sourceUrl} alt="" className="img-fluid"/></Col>
      <Col sm={8} xs={6} className="text-secondary">
      <Calendar className="text-warning"></Calendar> {formatDate(date)}
        <h2 className="malayalam text-warning fs-6">
        <Link to={`${uri}`} key={title} className=" text-secondary text-decoration-none"> {title}</Link>
        </h2>
        
      </Col>
      
      </Row>
    
      ));
}}