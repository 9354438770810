import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import logo from '../images/logo.png'; // with import    
import SiteMenu from './SiteMenu';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
export default function SiteHeader(){
    return(
        <Navbar bg="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/"><img src={logo} alt=""/></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
        <SiteMenu/>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
}
