import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Calendar,Tags } from 'react-bootstrap-icons';
import RelatedTags from '../components/RelatedTags';
const formatDate = (date) => new Date(date).toLocaleDateString();

export default function SinglePost({ post }) {
    const haveCategories = Boolean(post.categories?post.categories:0);
    const haveTags = Boolean(post.tags?post.tags:'');
  return (
        <div key={post.title}>
<h1 className="malayalam fs-3 py-2 text-secondary">{post.title} </h1>
        <div className="postMeta">
        <Calendar></Calendar> {formatDate(post.date)}
        </div>
        {haveCategories ? (
        <div className="categories-list">
          <ul>
            {post.categories.nodes.map((category) => {
              const { uri, name } = category;
              return (
                <Link to={`${uri}`} key={name}>
                  <li key={name}><span>{name}</span></li>
                </Link>
              );
            })}
          </ul>
        </div>
      ) : null}
        <hr className="text-secondary mt-3"/>
        <img src={post.featuredImage.node.sourceUrl} alt="" className="img-fluid my-3"/>
        <div className="postContent">{parse(post.content)}</div>
        {haveTags ? (
        <div className="categories-list">
        <Tags className="text-secondary fs-4"></Tags>&nbsp;
            {post.tags.nodes.map((tags) => {
              const { slug, name } = tags;
              return (
                <Link to={`/tags/${slug}`} key={name} className="text-secondary text-decoration-none">
                  <span key={name} className="malayalam">{name}</span>
                </Link>
              );
            })}
        </div>
      ) : null}
      <div className="RelatedPosts py-2">
        <h2 className="fs-4 english py-3 text-warning">You may also like!</h2>
        <RelatedTags post={post.tags.nodes[0]}></RelatedTags>
      </div>
      
      </div>
    
  
)}

        
        