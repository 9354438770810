import React from 'react';
import {ApolloProvider, ApolloClient, InMemoryCache} from '@apollo/client';
import { Route,Routes} from 'react-router-dom';
import "./index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HttpLink } from 'apollo-link-http';
import SiteHeader from './components/SiteHeader';
import SiteFooter from './components/SiteFooter';
import HomePage from './Pages/HomePage';
import CategoryPage from "./Pages/CategoryPage";
import TagPage from "./Pages/TagPage";
import PostPage from "./Pages/PostPage";
const client = new ApolloClient({
  link: new HttpLink({ uri: 'https://cms.chithrabhumi.com/graphql' }),
  cache: new InMemoryCache()
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <SiteHeader/>
  <Routes>
  <Route exact path="/" element={<HomePage/>}/>
  <Route path="/category/:slug" element={<CategoryPage/>} />
  <Route path="/tags/:slug" element={<TagPage/>} />
  <Route path="/:slug/:slug/:id" element={<PostPage/>} />
   </Routes>
   <SiteFooter/>
     </ApolloProvider>
  );
}
