import React from "react";
import { useParams } from "react-router-dom";
import PostPageContent from "../components/PostPageContent";
import { gql, useQuery } from "@apollo/client";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import SidePost from '../components/SidePost';
const GET_POST_BY_SLUG = gql`
  query MyQuery($slug:String!) {
    posts(where: {tag: $slug}) {
         nodes {
      date
      featuredImage {
        node {
          altText
          sourceUrl
        }
      }
      postId
      slug
      title
      uri
      excerpt
    }
    }
  }
`;

export default function TagPage() {
    let { slug } = useParams();
  const { loading, error, data } = useQuery(GET_POST_BY_SLUG, {
    variables: {
      slug: slug
    }
  });
  const postFound = Boolean(data?data:'');
  return (
    <div className="page-container bg-dark">
     
      <Container className="p-3">
      <Breadcrumb>
      <Breadcrumb.Item href="/" className="fs-6 text-light">Home</Breadcrumb.Item>
      <Breadcrumb.Item active className="fs-6">
      
       {slug.replaceAll('-', ' ')}
      </Breadcrumb.Item>
      
    </Breadcrumb>

      {loading ? (
        <p className="text-secondary">Loading...</p>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : !postFound ? (
        <p>Post could not be found.</p>
      ) : (
        <Row>
        <Col sm={9}><PostPageContent post={data.posts} /></Col>
        <Col sm={3}>
        <h2 className="fs-4 py-2 english mb-3 text-warning border-top border-bottom">Latest</h2>
        <SidePost limit={5}></SidePost>
        </Col>
      </Row>
        
      )}
      </Container>
    </div>
  );
}
