import "bootstrap/dist/css/bootstrap.min.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Facebook,Twitter,Instagram } from 'react-bootstrap-icons';
export default function SiteFooter(){
    return(
        <div className="bg-dark">
        <Container>
        <Row className="py-5">
          <Col>
          <h3 className="english pb-4 text-light fs-5">Visit</h3>
          <ul>
            <li><a rel="noreferrer" className="text-right text-secondary" href="https://keralafamily.com" target="_blank">Keralafamily</a></li>
            <li><a rel="noreferrer" className="text-right text-secondary" href="https://keralafinance.com" target="_blank">Keralafinance</a></li>
            <li><a rel="noreferrer" className="text-right text-secondary" href="https://keralaonlive.com" target="_blank">Keralaonlive</a></li>
            <li><a rel="noreferrer" className="text-right text-secondary" href="https://jobcloud.in" target="_blank">Jobcloud</a></li>
            <li><a rel="noreferrer" className="text-right text-secondary" href="https://kadalundi.com" target="_blank">Kadalundi</a></li>

          </ul>

          </Col>
          <Col>
          <h3 className="english pb-4 text-light fs-5">Quick Links</h3>
          <ul>
            <li><a rel="noreferrer" className="fs-5 text-secondary text-decoration-none" href="/category/latest-news-from-movie-world/">Film News</a></li>
            <li><a rel="noreferrer" className="fs-5 text-secondary text-decoration-none" href="/category/trailer/">Trailers</a></li>
            <li><a rel="noreferrer" className="fs-5 text-secondary text-decoration-none" href="/category/teaser">Teasers</a></li>
            <li><a rel="noreferrer" className="fs-5 text-secondary text-decoration-none" href="/">Gallery</a></li>

          </ul></Col>
          <Col>
          <h3 className="english pb-4 text-light fs-5">Follow us on</h3>
          <ul class="list-inline">
            <li class="list-inline-item mr-3"><a href="https://www.facebook.com/talkiesonline" rel="noreferrer" target="_blank"><Facebook className="fs-2"></Facebook></a></li>
            <li class="list-inline-item mr-3"><a href="https://twitter.com/talkiesonline" rel="noreferrer" target="_blank"><Twitter className="fs-2 text-blue"></Twitter></a></li>
            <li class="list-inline-item"><a href="https://www.instagram.com/talkiesonline/" rel="noreferrer" target="_blank"><Instagram className="fs-2 text-purple"></Instagram></a></li>
            </ul>
          </Col>
        </Row>
        <Row className="bg-dark pt-2 pb-3 border-top">
          <Col className="fs-6 text-secondary">Copyright &copy; 2022. All rights reserved</Col>
          <Col className="justify-content-end d-flex"><a rel="noreferrer" className="text-right text-secondary" href="https://prajital.com" target="_blank">Prajital</a></Col>
        </Row>
      </Container></div>
    );
}
