import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Calendar } from 'react-bootstrap-icons';
const formatDate = (date) => new Date(date).toLocaleDateString();
// This is the query that Apollo Client will send to the WP site.
const POSTS_SEARCH_QUERY = gql`
  query POSTS_SEARCH_QUERY{
    posts(first: 10, where: {offsetPagination: {offset: 8, size: 10}}) {
      nodes {
        categories {
          nodes {
            id
            link
            name
          }
        }
        postId
        uri
        date
        excerpt
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;
export default function PostList() {
  const { loading, error, data } = useQuery(POSTS_SEARCH_QUERY);
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
    return data.posts.nodes.map(({ postId, title,featuredImage,excerpt,uri,date}) => (
      <Row className="pb-4">
      <Col sm={4} xs={12}><img src={featuredImage.node.sourceUrl} alt="" className="img-fluid"/></Col>
      <Col sm={8} xs={12} className="text-secondary">
      <Calendar className="text-warning"></Calendar> {formatDate(date)}
        <h2 className="malayalam fs-5">
          <Link to={`${uri}`} key={title} className=" text-warning text-decoration-none"> {title}</Link>
        </h2>
        <p className="malayalam text-light">
        { parse((excerpt.substring(3,150)+'...'))}
        </p>
      </Col>
      
      </Row>
    
      ));
}