import React from "react";
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import parse from "html-react-parser";
import { Calendar } from 'react-bootstrap-icons';
const formatDate = (date) => new Date(date).toLocaleDateString();

export default function PostPageContent({ post }) {
  return post.nodes.map(({ postId,date,uri,title,featuredImage,excerpt}) => (
        <Row className="pb-4" key={postId}>
        <Col sm={4} xs={12}><img src={featuredImage.node.sourceUrl} alt="" className="img-fluid"/></Col>
        <Col sm={8} xs={12}>
          
          <h2 className="malayalam fs-4">
          <Link to={`${uri}`} className="text-warning text-decoration-none"> {title}</Link>
           
          </h2>
          <div className="p-1 text-light">
           <Calendar></Calendar> {formatDate(date)}
          </div>
          <div className="malayalam text-light">
          { parse(excerpt.substring(0,150)+'...')}
          </div>
        </Col>
        
        </Row>
        ));
  
}
